import { register, init } from "marko/dist/runtime/components/index.js";
import "./style.scss";
import "./logo.svg";
import "../../components/app-layout/favicon.ico";
import "../../components/app-layout/favicon.svg";
import "../../components/app-navbar/style.scss";
import "../../components/app-navbar/logo.svg";
import "../../components/doc-search/index.marko";
import "../../components/app-user-notifications/index.marko";
import component_0 from "../../components/app-navbar/component-browser.js";
register("eEURCRj", component_0);
import component_1 from "../../components/app-cookie-consent/component-browser.js";
register("RG6_GLd", component_1);
import "../../components/app-login/index.marko";
init("heracles_web");