import { createId } from '@paralleldrive/cuid2';

import { OLD_MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from '../../utils/const/passwords.mjs';

<app-notification key="notification" />

$ {
  let elementId;

  const {
    githubAuthHref,
    googleAuthHref,
    hasPasskeysLogin,
    hasPasskeysSupport,
    hasPasswordLogin,
    qualcommAuthHref,
    shellAuthHref,
  } = input;
  const { isLoading, isSocialLoading } = state;
  const canUsePasskeys = hasPasskeysSupport && hasPasskeysLogin;
}

<div class:no-update="columns is-centered">
  <div class:no-update="column is-two-fifths">
    <app-box>
      <form key="form" method="post" accept-charset="utf-8" autocomplete="on" on-submit("onLoginFormSubmit")>
        <fieldset disabled=((isLoading || isSocialLoading) && true)>
          <div no-update class="field login-field" aria-hidden="true">
            $ elementId = createId();
            <input id:scoped=elementId type="text" name=`login_${elementId}` tabindex="-1" value="">
          </div>

          <if(hasPasswordLogin || canUsePasskeys)>
            <div class:no-update="field">
              $ elementId = createId();
              <label class:no-update="label" for:scoped=elementId>Email address</label>
              <div class:no-update="control">
                <input id:scoped=elementId class:no-update="input" type="email" inputmode="email" name="email" autocomplete="username webauthn" autofocus required />
              </div>
            </div>
          </if>

          <if(hasPasswordLogin)>
            <div class:no-update="field">
              $ elementId = createId();
              <label class:no-update="label" for:scoped=elementId>Password</label>
              <div class:no-update="control">
                <input id:scoped=elementId class:no-update="input" minlength=OLD_MIN_PASSWORD_LENGTH maxlength=MAX_PASSWORD_LENGTH type="password" inputmode="text" name="password" autocomplete="current-password" required />
              </div>
              <p no-update class="has-text-right mt-2">
                <a href="/login/forgot">Forgot your password?</a>
              </p>
            </div>
          </if>

          <if(hasPasswordLogin || canUsePasskeys)>
            <div class:no-update="field mt-4">
              <div class:no-update="control">
                <div class:no-update="is-flex is-flex-direction-column is-flex-grow-1 has-row-gap-2">
                  <if(hasPasswordLogin)>
                    <div>
                      <button no-update-body name="login" type="submit" class=["button is-dark is-fullwidth is-justify-content-center", isLoading && "is-loading"]>
                        <span class="icon-text">
                          <span class="icon" aria-hidden="true">
                            <i class="fa-regular fa-key"></i>
                          </span>
                          <span>
                            Log in with Password
                          </span>
                        </span>
                      </button>
                    </div>
                  </if>

                  <if(canUsePasskeys)>
                    <if(hasPasswordLogin)>
                      <p no-update class="has-text-centered is-uppercase has-text-grey has-text-weight-semibold is-size-7">or</p>
                    </if>
                    <div>
                      <button no-update-body name="login-pk" class=["button is-dark is-fullwidth is-justify-content-center", isLoading && "is-loading"] on-click("handlePasskeyClick")>
                        <span class="icon-text">
                          <span class="icon" aria-hidden="true">
                            <i class="fa-light fa-fingerprint"></i>
                          </span>
                          <span>
                            Log in with Passkey
                          </span>
                        </span>
                      </button>
                    </div>
                  </if>
                </div>
              </div>
            </div>
          </if>

        </fieldset>
      </form>

      <if(githubAuthHref || googleAuthHref || qualcommAuthHref || shellAuthHref)>
        <if(hasPasswordLogin || canUsePasskeys)>
          <div no-update class="divider">Or continue with</div>
        </if>

        <fieldset disabled=((isSocialLoading || isLoading) && true)>

          <div class:no-update="buttons">

            <if(githubAuthHref)>
              <a no-update-body aria-label="Log in with GitHub" data-login="github" rel="nofollow" href=githubAuthHref role="button" class=["button is-dark is-fullwidth", isSocialLoading && "is-loading" ] data-no-instant on-click("onSocialLoginClick", "github")>
                <span class="icon-text">
                  <span aria-hidden="true" class="icon">
                    <i class="fa-brands fa-github"></i>
                  </span>
                  <span>Log in with GitHub</span>
                </span>
              </a>
            </if>

            <if(googleAuthHref)>
              <a no-update-body aria-label="Log in with Google" data-login="google" rel="nofollow" href=googleAuthHref role="button" class=["button is-dark is-fullwidth", isSocialLoading && "is-loading"] data-no-instant on-click("onSocialLoginClick", "google")>
                <span class="icon-text">
                  <span aria-hidden="true" class="icon">
                    <i class="fa-brands fa-google"></i>
                  </span>
                  <span>Log in with Google</span>
                </span>
              </a>
            </if>

            <if(qualcommAuthHref)>
              <a no-update-body aria-label="Log in with Qualcomm ID" data-login="qualcomm" rel="nofollow" href=qualcommAuthHref role="button" class=["button is-dark is-fullwidth", isSocialLoading && "is-loading" ] data-no-instant on-click("onSocialLoginClick", "qualcomm")>
                <span class="icon-text">
                  <span aria-hidden="true" class="icon">
                    <i class="fa-kit fa-brand-qualcomm"></i>
                  </span>
                  <span>Log in with Qualcomm ID</span>
                </span>
              </a>
            </if>

            <if(shellAuthHref)>
              <a no-update-body aria-label="Log in with Shell ID" data-login="shell" rel="nofollow" href=shellAuthHref role="button" class=["button is-dark is-fullwidth", isSocialLoading && "is-loading" ] data-no-instant on-click("onSocialLoginClick", "shell")>
                <span class="icon-text">
                  <span aria-hidden="true" class="icon">
                    <i class="fa-kit fa-brand-shell"></i>
                  </span>
                  <span>Log in with Shell ID</span>
                </span>
              </a>
            </if>

          </div>

        </fieldset>
      </if>
    </app-box>
  </div>
</div>
